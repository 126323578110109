<template>
  <!-- Automation -->
  <section class="section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4 fw-bold">Automated Procurement Workflow</h4>
            <p class="text-muted para-desc mb-0 mx-auto">Bid good-bye to paper processes &amp; slow manual
              approvals<br />
              and say hello to <strong class="text-primary fw-bold">Automated procurement</strong></p>
          </div>
        </div><!--end col-->
      </div><!--end row-->

      <div class="row">
        <div class="col-lg-4 col-md-4 mt-4 pt-2">
          <div
            class="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
            <span class="h1 icon-color">
              <i class="uil uil-swatchbook"></i>
            </span>
            <div class="card-body p-0 content">
              <h5 class="text-muted fw-bold">Workflow Management</h5>
              <p class="para mb-0">
                Define the rules and process flows up front, enforcing compliance by users as they run through a process.
              </p>
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-swatchbook"></i>
            </span>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-4 mt-4 pt-2">
          <div
            class="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
            <span class="h1 icon-color">
              <i class="uil uil-award"></i>
            </span>
            <div class="card-body p-0 content">
              <h5 class="text-muted fw-bold">Bidding Rules</h5>
              <p class="para mb-0">
                Single envelope bids<br />
                vs.<br />
                Two envelope bidding
              </p>
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-award"></i>
            </span>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-4 mt-4 pt-2">
          <div
            class="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
            <span class="h1 icon-color">
              <i class="uil uil-robot"></i>
            </span>
            <div class="card-body p-0 content">
              <h5 class="text-muted fw-bold">Automated Comparisons</h5>
              <p class="para mb-0">
                Evaluate vendor offers, collaborate with your team when determining which offer to accept
              </p>
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-robot"></i>
            </span>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-4 mt-4 pt-2">
          <div
            class="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
            <span class="h1 icon-color">
              <i class="uil uil-sitemap"></i>
            </span>
            <div class="card-body p-0 content">
              <h5 class="text-muted fw-bold">Approval Workflows</h5>
              <p class="para text-muted mb-0">
                Maximize process compliance by creating documented approvals at each stage of the procurement process
              </p>
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-sitemap"></i>
            </span>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-4 mt-4 pt-2">
          <div
            class="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
            <span class="h1 icon-color">
              <i class="uil uil-browser"></i>
            </span>
            <div class="card-body p-0 content">
              <h5 class="text-muted fw-bold">Automated Matching</h5>
              <p class="para text-muted mb-0">
                Achieve 3 way matching. <br />Compare items received and items ordered.
              </p>
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-browser"></i>
            </span>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-4 mt-4 pt-2">
          <div
            class="card features feature-primary feature-full-bg rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
            <span class="h1 icon-color">
              <i class="uil uil-history"></i>
            </span>
            <div class="card-body p-0 content">
              <h5 class="text-muted fw-bold">Audit Log</h5>
              <p class="para text-muted mb-0">
                Maintain complete trail of user transactions. authorizations. Easily reconcile authorized invoices with payments made
              </p>
            </div>
            <span class="big-icon text-center">
              <i class="uil uil-historyalt"></i>
            </span>
          </div>
        </div><!--end col-->

        <div class="col-lg-12 text-center col-md-4 mt-4 pt-2">
          <router-link to="/product/feature-overview" class="btn btn-outline-primary btn-pills">Read more <i class="uil uil-arrow-right"></i></router-link>
        </div><!--end col-->
      </div><!--end row-->
    </div><!--end container-->
  </section><!--end section-->
</template>

<script setup></script>
<template>
  <!-- why us -->
  <section class="section overflow-hidden">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
            <h4 class="title fw-semibold my-4">
              Streamlined Procurement for <strong class="text-primary">maximum ROI</strong>
            </h4>
            <p class="text-muted para-desc mb-0 mx-auto">
              Reinvest
              <span class="text-primary fw-bold">measurable time</span> &amp;
              <span class="text-primary fw-bold">cost savings</span> back into your business.
            </p>
          </div>
        </div><!--end col-->
      </div><!--end row-->

      <div class="row align-items-center">
        <div class="col-lg-7 mt-4 pt-2">
          <div class="saas-feature-shape-left position-relative me-lg-5 wow animate__animated animate__fadeInUp"
            data-wow-delay=".5s">
            <img src="/assets/images/pages/home/Image3.png" class="img-fluid mx-auto d-block rounded" alt="">
          </div>
        </div><!--end col-->

        <div class="col-lg-5 mt-4 pt-2">
          <div class="section-title wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
            <h4 class="title mb-4 text-muted fw-bold">Our Value Proposition...</h4>
            <ul class="list-unstyled text-muted">
              <li class="mb-1">
                <span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                Eliminate unauthorized purchases
              </li>
              <li class="mb-1">
                <span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                Better compliance and reduced errors
              </li>
              <li class="mb-1">
                <span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                Increase fairness and transparency
              </li>
              <li class="mb-1">
                <span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                Reduced ordering cost
              </li>
              <li class="mb-1">
                <span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                Fully digitize your business procurement
              </li>
              <li class="mb-1">
                <span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>
                Achieve competitive bids
              </li>
            </ul>
          </div>
        </div><!--end col-->
      </div><!--end row-->
    </div><!--end container-->

    <div class="container mt-100 mt-60">
      <div class="row align-items-center">
        <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
          <div class="section-title wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
            <h1 class="title mb-3 text-muted fw-bold">Why Choose us ?</h1>
            <p class="para-desc text-muted">Procurement may be complex. Your procurement software doesn't need to be.</p>

            <div class="row">
              <div class="col-12">
                <div class="d-flex align-items-center pt-4">
                  <h2><i class="uil uil-cloud-check fs-16 text-primary"></i></h2>
                  <div class="ms-3">
                    <h6 class="text-muted">Simple, transparent workflow</h6>
                    <p class="text-muted mb-0">Make your work effortlessly visible to save time and money through
                      competitive tendering</p>
                  </div>
                </div>
              </div><!--end col-->

              <div class="col-12">
                <div class="d-flex align-items-center pt-4">
                  <h2><i class="uil uil-cloud-check fs-16 text-primary"></i></h2>
                  <div class="ms-3">
                    <h6 class="text-muted">Business digitization</h6>
                    <p class="text-muted mb-0">Go paperless, work from anywhere... run your entire procure-to-pay
                      process online</p>
                  </div>
                </div>
              </div><!--end col-->

              <div class="col-12">
                <div class="d-flex align-items-center pt-4">
                  <h2><i class="uil uil-cloud-check fs-16 text-primary"></i></h2>
                  <div class="ms-3">
                    <h6 class="text-muted">Complete Transparency & Visibility</h6>
                    <p class="text-muted mb-0">
                      Track and control every purchase from request to payment, ensuring more compliant and effective
                      procurement
                    </p>
                  </div>
                </div>
              </div><!--end col-->

              <div class="col-12">
                <div class="d-flex align-items-center pt-4">
                  <h2><i class="uil uil-cloud-check fs-16 text-primary"></i></h2>
                  <div class="ms-3">
                    <h6 class="text-muted">Affordable alternative to legacy providers</h6>
                    <p class="text-muted mb-0">
                      CromaRFx offers the most complete procure-to-pay feature set at an accessible price.
                    </p>
                  </div>
                </div>
              </div><!--end col-->

            </div>
          </div>
        </div><!--end col-->

        <div class="col-lg-7 order-1 order-lg-2 wow animate__animated animate__fadeInUp" data-wow-delay=".7s">
          <div class="saas-feature-shape-right position-relative ms-lg-5">
            <img src="/assets/images/pages/home/Image4.png" class="img-fluid mx-auto d-block rounded" alt="">
          </div>
        </div><!--end col-->
      </div><!--end row-->
    </div><!--end container-->
  </section><!--end section-->
</template>
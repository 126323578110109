import { ApolloClient, InMemoryCache } from "@apollo/client/core";
import { createUploadLink } from "apollo-upload-client";
import { setContext } from "@apollo/client/link/context";

const httpLink = createUploadLink({
	uri:process.env.VUE_APP_API_URL,
	credentials: "include",
});

const authLink = setContext((_, { headers }) => {
	const token = localStorage.getItem('token')
	return {
		headers: {
			...headers,
			authorization: token ?? "",
			// "Apollo-Require-Preflight": "true",
		},
	};
});
const apolloClient = new ApolloClient({
	connectToDevTools: process.env.NODE_ENV == "development",
	link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
	defaultOptions: {
		mutate: {
			errorPolicy: "all",
		},
		// fetchOptions: {
		// 	mode: "no-cors",
		// },
	},
});

export default apolloClient;

<template>
  <!-- Industries -->
  <section class="section bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 pb-2">
            <h4 class="title mb-4 fw-bold">Our Industrial <span class="text-primary">Footprint</span></h4>
            <p class="text-muted para-desc mx-auto mb-0">Our solution custs across <span class="text-primary fw-bold">all
                indutsries</span> adding value to<br />
              small and medium-sized businesses.</p>
          </div>
        </div><!--end col-->
      </div><!--end row-->

      <div class="row">
        <div class="col-lg-4 col-md-6 mt-4 pt-2">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
            <div class="icon text-center rounded-circle me-3">
              <img src="/assets/images/pages/industries/healthcare.png" style="width: 30px;" />
            </div>
            <div class="flex-1">
              <h4 class="title mb-0">Healthcare</h4>
            </div>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-6 mt-4 pt-2">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
            <div class="icon text-center rounded-circle me-3">
              <img src="/assets/images/pages/industries/automobile.png" style="width: 30px;" />
            </div>
            <div class="flex-1">
              <h4 class="title mb-0">Automobile</h4>
            </div>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-6 mt-4 pt-2">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
            <div class="icon text-center rounded-circle me-3">
              <img src="/assets/images/pages/industries/education.png" style="width: 30px;" />
            </div>
            <div class="flex-1">
              <h4 class="title mb-0">Education</h4>
            </div>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-6 mt-4 pt-2">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
            <div class="icon text-center rounded-circle me-3">
              <img src="/assets/images/pages/industries/start-up.png" style="width: 30px;" />
            </div>
            <div class="flex-1">
              <h4 class="title mb-0">Startups</h4>
            </div>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-6 mt-4 pt-2">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
            <div class="icon text-center rounded-circle me-3">
              <img src="/assets/images/pages/industries/manufacturing.png" style="width: 30px;" />
            </div>
            <div class="flex-1">
              <h4 class="title mb-0">Manufacturing</h4>
            </div>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-6 mt-4 pt-2">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
            <div class="icon text-center rounded-circle me-3">
              <img src="/assets/images/pages/industries/professional-services.png" style="width: 30px;" />
            </div>
            <div class="flex-1">
              <h4 class="title mb-0">Professional Services</h4>
            </div>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-6 mt-4 pt-2">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
            <div class="icon text-center rounded-circle me-3">
              <img src="/assets/images/pages/industries/retail.png" style="width: 30px;" />
            </div>
            <div class="flex-1">
              <h4 class="title mb-0">Retail</h4>
            </div>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-6 mt-4 pt-2">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
            <div class="icon text-center rounded-circle me-3">
              <img src="/assets/images/pages/industries/construction.png" style="width: 30px;" />
            </div>
            <div class="flex-1">
              <h4 class="title mb-0">Construction</h4>
            </div>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-6 mt-4 pt-2">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
            <div class="icon text-center rounded-circle me-3">
              <img src="/assets/images/pages/industries/logistics.png" style="width: 30px;" />
            </div>
            <div class="flex-1">
              <h4 class="title mb-0">Logistics</h4>
            </div>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-6 mt-4 pt-2">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
            <div class="icon text-center rounded-circle me-3">
              <img src="/assets/images/pages/industries/hospitality.png" style="width: 30px;" />
            </div>
            <div class="flex-1">
              <h4 class="title mb-0">Hospitality</h4>
            </div>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-6 mt-4 pt-2">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
            <div class="icon text-center rounded-circle me-3">
              <img src="/assets/images/pages/industries/finance.png" style="width: 30px;" />
            </div>
            <div class="flex-1">
              <h4 class="title mb-0">Finance</h4>
            </div>
          </div>
        </div><!--end col-->

        <div class="col-lg-4 col-md-6 mt-4 pt-2">
          <div class="d-flex features feature-primary key-feature align-items-center p-3 rounded shadow bg-white">
            <div class="icon text-center rounded-circle me-3">
              <img src="/assets/images/pages/industries/advertising.png" style="width: 30px;" />
            </div>
            <div class="flex-1">
              <h4 class="title mb-0">Media</h4>
            </div>
          </div>
        </div><!--end col-->
      </div><!--end row-->
    </div><!--end container-->
  </section><!--end section-->
</template>
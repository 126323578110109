<template>
  <section class="bg-home pb-5 pb-sm-0 d-flex align-items-center bg-linear-gradient-primary">
    <div class="container">
      <div class="row mt-5 align-items-center">
        <div class="col-md-6">
          <div class="title-heading me-lg-4 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
            <h1 class="heading fw-bold mb-3">
              Standardize your
              <span class="text-primary">procurement</span> procedure
            </h1>
            <p class="para-desc text-muted">
              Simplify your business procurement with our user-friendly, cloud-based e-procurement solution. Streamline
              processes, enhance transparency, while boost efficiency.
            </p>
            <p class="para-desc text-muted">
              CromaRFx supports the complete Procure-to-Pay process for reduced leakages and increased productivity.
            </p>

            <div class="mt-4">
              <router-link to="/company/contacts" class="btn btn-primary btn-pills mt-4"><i class="uil uil-phone"></i>
                Contact
                us</router-link>
            </div>
          </div>
        </div><!--end col-->

        <div class="col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
          <div class="position-relative ms-lg-5">
            <div class="bg-half-260 overflow-hidden rounded-md shadow-md" style="background: url('/assets/images/pages/home/Image1.png');">
              <div class="py-lg-5 py-md-0 py-5"></div>
            </div>

            <div class="modern-saas-absolute-left wow animate__animated animate__fadeInUp" data-wow-delay=".3s">
              <div class="card">
                <div
                  class="features feature-primary d-flex justify-content-between align-items-center rounded shadow p-3">
                  <div class="d-flex align-items-center">
                    <div class="icon bg-soft-primary text-center rounded-pill">
                      <i class="uil uil-usd-circle fs-4 mb-0"></i>
                    </div>
                    <div class="flex-1 ms-3">
                      <h6 class="mb-0 text-muted">Savings</h6>
                      <p class="fs-5 text-dark fw-bold mb-0">
                        $
                        <NumberAnimation 
                          :from=" 45000 " 
                          :to=" 211688 " 
                          :duration=" 3 " 
                          :format=" formatNumber " 
                          autoplay
                          easing="linear"  
                        />
                      </p>
                    </div>
                  </div>

                  <span class="text-success ms-4"><i class="uil uil-arrow-growth"></i> 48.4%</span>
                </div>
              </div>
            </div>

            <div class="modern-saas-absolute-right wow animate__animated animate__fadeInUp" data-wow-delay=".5s">
              <div class="card rounded shadow">
                <div class="p-3">
                  <h5>Manage Your Workflow</h5>

                  <div class="progress-box mt-2">
                    <h6 class="title fw-normal text-muted">Work in dashboard</h6>
                    <div class="progress">
                      <div class="progress-bar position-relative bg-primary" style="width:88%;">
                        <div class="progress-value d-block text-muted h6 mt-1">88%</div>
                      </div>
                    </div>
                  </div><!--end process box-->
                </div>
              </div>
            </div>

            <div class="position-absolute top-0 start-0 translate-middle z-index-m-1">
              <img src="/assets/images/shapes/dots.svg" class="avatar avatar-xl-large" alt="">
            </div>
          </div>
        </div><!--end col-->
      </div><!--end row-->
    </div><!--end container-->
  </section><!--end section-->
</template>

<script setup>
import NumberAnimation from "vue-number-animation";
const formatNumber = (number) => number.toFixed(0)

</script>
import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/pages/home/index.vue";
import { useAuthStore } from "@/store/useAuth";

const routes = [
	{
		path: "/",
		component: HomeView,
		meta: { title: "Welcome" },
	},

	/* product */
	{
		path: "/product/overview",
		component: () => import("@/pages/home/index.vue"),
		meta: { title: "Product Overview" },
	},
	{
		path: "/product/feature-overview",
		component: () => import("@/pages/features/index.vue"),
		meta: { title: "Feature Overview" },
	},
	{
		path: "/product/process-workflow",
		component: () => import("@/pages/process/index.vue"),
		meta: { title: "Process Workflow" },
	},
	{
		path: "/product/pricing",
		component: () => import("@/pages/company/pricing.vue"),
		meta: { title: "Pricing" },
	},

	/* solution */
	{
		path: "/solution/srm",
		component: () => import("@/pages/srm/index.vue"),
		meta: { title: "Supplier Management" },
	},
	{
		path: "/solution/procure-to-pay",
		component: () => import("@/pages/procure/index.vue"),
		meta: { title: "Procure-2-Pay" },
	},
	{
		path: "/solution/integration",
		component: () => import("@/pages/integration/index.vue"),
		meta: { title: "Integrations" },
	},
	{
		path: "/solution/bi",
		component: () => import("@/pages/bi/index.vue"),
		meta: { title: "Business Intelligence" },
	},

	/* company */
	{
		path: "/company/about",
		component: () => import("@/pages/company/about.vue"),
		meta: { title: "About Us" },
	},
	{
		path: "/company/history",
		component: () => import("@/pages/company/history.vue"),
		meta: { title: "Our History" },
	},
	{
		path: "/company/careers",
		component: () => import("@/pages/company/careers.vue"),
		meta: { title: "Career Openings" },
	},
	{
		path: "/company/partnership",
		component: () => import("@/pages/company/partnership.vue"),
		meta: { title: "Partner with Us" },
	},
	{
		path: "/company/contacts",
		component: () => import("@/pages/company/contacts.vue"),
		meta: { title: "Contact Us" },
	},
	{
		path: "/company/book-demo",
		component: () => import("@/pages/company/book-demo.vue"),
		meta: { title: "Book a Demo" },
	},
	{
		path: "/company/terms",
		component: () => import("@/pages/company/terms.vue"),
		meta: { title: "Terms & Conditions" },
	},
	{
		path: "/company/privacy",
		component: () => import("@/pages/company/privacy.vue"),
		meta: { title: "Privacy" },
	},
	{
		path: "/resources/blog",
		component: () => import("@/pages/resources/blog.vue"),
		meta: { title: "Resource | Blog" },
	},
	{
		path: "/resources/articles/:id",
		component: () => import("@/pages/resources/article.vue"),
		meta: { title: "Resource | Article" },
	},
	{
		path: "/resources/case-studies",
		component: () => import("@/pages/resources/case-studies.vue"),
		meta: { title: "Resource | Studies" },
	},
	{
		path: "/resources/e-books",
		component: () => import("@/pages/resources/e-books.vue"),
		meta: { title: "Resource | E-books" },
	},
	{
		path: "/resources/guides",
		component: () => import("@/pages/resources/guides.vue"),
		meta: { title: "Resource | Guides" },
	},
	{
		path: "/resources/webinars",
		component: () => import("@/pages/resources/webinars.vue"),
		meta: { title: "Resource | Webinars" },
	},
	{
		path: "/resources/white-papers",
		component: () => import("@/pages/resources/white-papers.vue"),
		meta: { title: "Resource | White Papers" },
	},
	{
		path: "/resources/press-releases",
		component: () => import("@/pages/resources/press-releases.vue"),
		meta: { title: "Resource | Press Releases" },
	},

	/* auth */
	{
		path: "/auth/login",
		component: () => import("@/pages/auth/login.vue"),
		meta: { title: "Sign In" },
	},
	{
		path: "/auth/forgot-password",
		component: () => import("@/pages/auth/password.vue"),
		meta: { title: "Resource | Password" },
	},
	{
		path: "/auth/profile",
		component: () => import("@/pages/auth/profile.vue"),
		meta: {
			layout: "auth",
			requireAuth: true,
			title: "Auth | Profile",
			noScroll: true,
		},
	},
	{
		path: "/auth/contacts",
		component: () => import("@/pages/auth/contacts.vue"),
		meta: {
			layout: "auth",
			requireAuth: true,
			title: "Auth | Contacts",
			noScroll: true,
		},
	},
	{
		path: "/auth/team",
		component: () => import("@/pages/auth/team.vue"),
		meta: {
			layout: "auth",
			requireAuth: true,
			title: "Auth | Team",
			noScroll: true,
		},
	},
	{
		path: "/auth/history",
		component: () => import("@/pages/auth/history.vue"),
		meta: {
			layout: "auth",
			requireAuth: true,
			title: "Our History",
			noScroll: true,
		},
	},
	{
		path: "/auth/jobs",
		component: () => import("@/pages/auth/jobs.vue"),
		meta: {
			layout: "auth",
			requireAuth: true,
			title: "Auth | Jobs",
			noScroll: true,
		},
	},
	{
		path: "/auth/authors",
		component: () => import("@/pages/auth/authors.vue"),
		meta: {
			layout: "auth",
			requireAuth: true,
			title: "Authors",
			noScroll: true,
		},
	},
	{
		path: "/auth/categories",
		component: () => import("@/pages/auth/categories.vue"),
		meta: {
			layout: "auth",
			requireAuth: true,
			title: "Auth | Authors",
			noScroll: true,
		},
	},
	{
		path: "/auth/posts",
		component: () => import("@/pages/auth/posts.vue"),
		meta: {
			layout: "auth",
			requireAuth: true,
			title: "Auth | Posts",
			noScroll: true,
		},
	},
	{
		path: "/auth/pricing",
		component: () => import("@/pages/auth/pricing.vue"),
		meta: {
			layout: "auth",
			requireAuth: true,
			title: "Auth | Pricing",
			noScroll: true,
		},
	},
	{
		path: "/auth/partners",
		component: () => import("@/pages/auth/partnerslogo.vue"),
		meta: {
			layout: "auth",
			requireAuth: true,
			title: "Auth | Partners",
			noScroll: true,
		},
	},
	{
		path: "/auth/privacy-terms",
		component: () => import("@/pages/auth/privacy_terms.vue"),
		meta: {
			layout: "auth",
			requireAuth: true,
			title: "Auth | Partners",
			noScroll: true,
		},
	},

	/* catch-all */
	{
		path: "/:pathMatch(.*)*",
		component: () => import("@/pages/404.vue"),
		meta: { title: "404" },
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) return savedPosition;
		if (to.hash) return { el: to.hash };
		if (to.meta.noScroll) return;
		return { top: 0 };
	},
});

router.afterEach((to, from, next) => { 
	var toggler = document.getElementById("isToggle")
	if (toggler.classList.contains('open'))
		toggler.click()
})

router.beforeEach((to, from, next) => {
	document.title = to.meta.title;
	to.meta.requireAuth && !useAuthStore().loggedIn
		? next({ path: "/auth/login" })
		: next();
});

router.onError((error) => {
	if (/loading chunk \d* failed./i.test(error.message)) {
		window.location.reload();
	}
});

export default router;

import { defineStore } from "pinia";
import apolloClient from "../plugins/apollo";

export const useAuthStore = defineStore("auth", {
	state: () => ({
		loggedIn: localStorage.getItem("loggedIn") ?? false,
		user: JSON.parse(localStorage.getItem("user")) ?? null,
	}),

	actions: {
		async login(payload) {
			this.loggedIn = true;
			this.user = payload.user;

			localStorage.setItem("token", `Bearer ${payload.token}`);
			localStorage.setItem("loggedIn", true);
			localStorage.setItem("user", JSON.stringify(payload.user));

			await apolloClient.resetStore();
		},

		async profile(payload) {
			const existing = JSON.parse(localStorage.getItem("user"))
			if (payload.email) this.user = { ...existing, ...payload }
			else this.user = {...existing, profile: {...existing.profile, avatar: payload.avatar}}
			localStorage.setItem("user", JSON.stringify(this.user));

			await apolloClient.resetStore();
		},

		logout() {
			localStorage.removeItem("token");
			localStorage.removeItem("user");
			localStorage.removeItem("loggedIn");

			this.loggedIn = false;
			this.user = null;
		},
	},

	getters: {
		userInfo: (state) => state.user,
	},
});

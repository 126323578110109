<template>
  <!-- Workflow -->
  <section class="section bg-light">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 text-center">
          <div class="section-title mb-4 wow animate__animated animate__fadeInUp" data-wow-delay=".1s">
            <h4 class="title fw-bold my-4">Track, monitor &amp; manage workflows<br /> in <strong
                class="text-primary">real-time</strong></h4>
          </div>
        </div><!--end col-->
      </div><!--end row-->

      <div class="row justify-content-center align-items-center">
        <div class="col-lg-8 col-md-12">
          <div class="row mt-4 pt-2">
            <div class="col-md-6 col-12">
              <div class="d-flex features feature-primary pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i class="uil uil-user-circle fs-4"></i>
                </div>
                <div class="flex-1">
                  <h4 class="title fw-bold">Supplier Relationship Management</h4>
                  <p class="text-muted para mb-0">
                    Easily manage & track supplier information, performance and risk.
                  </p>
                </div>
              </div>
            </div><!--end col-->

            <div class="col-md-6 col-12">
              <div class="d-flex features feature-primary pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i class="uil uil-check-circle fs-4"></i>
                </div>
                <div class="flex-1">
                  <h4 class="title fw-bold">Requisition Management</h4>
                  <p class="text-muted para mb-0">
                    Standardize and streamline the purchasing process from request to payment.
                  </p>
                </div>
              </div>
            </div><!--end col-->

            <div class="col-md-6 col-12">
              <div class="d-flex features feature-primary pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i class="uil uil-file fs-4"></i>
                </div>
                <div class="flex-1">
                  <h4 class="title fw-bold">Strategic e-Sourcing</h4>
                  <p class="text-muted para mb-0">
                    Easily create RFx events and both forward and reverse e-auctions.
                    Identify, select and collaborate with best vendors to see bottom-line effect with huge savings , best
                    value &amp; operational efficiency.
                  </p>
                </div>
              </div>
            </div><!--end col-->

            <div class="col-md-6 col-12">
              <div class="d-flex features feature-primary pt-4 pb-4">
                <div class="icon text-center rounded-circle text-primary me-3 mt-2">
                  <i class="uil uil-shopping-cart fs-4"></i>
                </div>
                <div class="flex-1">
                  <h4 class="title fw-bold">Purchase Order Management</h4>
                  <p class="text-muted para mb-0">
                    Ensure proper tracking and approval of every purchase order
                    by standardizing the process to reduce maverick spend.
                  </p>
                </div>
              </div>
            </div><!--end col-->
          </div><!--end row-->
        </div><!--end col-->

        <div class="col-lg-4 col-md-12 col-12 mt-4 pt-2 text-center text-md-end">
          <img src="/assets/images/pages/home/Image2.png" class="img-fluid" alt="">
        </div><!--end col-->
      </div><!--end row-->
    </div><!--end container-->
  </section><!--end section-->
</template>

<script setup></script>
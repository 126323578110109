<template>
  <div class="col-lg-4 col-md-6 col-12 d-lg-block d-none">
    <div class="sidebar sticky-bar p-4 rounded shadow">
      <div class="widget mt-4">
        <ul class="list-unstyled sidebar-nav mb-0" id="navmenu-nav">
          
          <li :class="`navbar-item account-menu px-0 ${linkIndex == 0 ? 'mt-0' : 'mt-2'}`" v-for="( link, linkIndex  ) in  links " :key=" `link-${ linkIndex }` ">
            <router-link :to="`/auth/${link.to}`" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
              <span class="h4 mb-0"><i :class="`uil uil-${link.icon}`"></i></span>
              <h6 class="mb-0 ms-2">{{link.name}}</h6>
            </router-link>
          </li>

          <li class="navbar-item account-menu px-0 mt-2">
            <a href="javascript:void(0)" @click="doLogout" class="navbar-link d-flex rounded shadow align-items-center py-2 px-4">
              <span class="h4 mb-0"><i class="uil uil-sign-out-alt"></i></span>
              <h6 class="mb-0 ms-2">Logout</h6>
            </a>
          </li>
        </ul>
      </div>

    </div>
  </div><!--end col-->
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/store/useAuth'

const auth = useAuthStore()
const router = useRouter()

const links = [
  {to: 'profile', name: 'Profile', icon: 'info-circle'},
  {to: 'authors', name: 'Authors', icon: 'users-alt'},
  {to: 'contacts', name: 'Contacts', icon: 'phone'},
  {to: 'team', name: 'Team', icon: 'user-circle'},
  {to: 'history', name: 'History', icon: 'clock'},
  {to: 'categories', name: 'Categories', icon: 'code-branch'},
  {to: 'posts', name: 'Posts', icon: 'file-share-alt'},
  {to: 'pricing', name: 'Pricing', icon: 'dollar-sign'},
  {to: 'partners', name: 'Partners', icon: 'users-alt'},
  {to: 'privacy-terms', name: 'Privacy+Terms', icon: 'shield-check'}
]

const doLogout = () => {
  auth.logout()
  router.push({ path: "/auth/login" })
}

</script>
<template>
  <header id="topnav" class="defaultscroll sticky bg-white">
    <div class="container">
      <router-link class="logo" to="/" exact>
        <img src="/assets/images/logo.png" height="48" class="logo-light-mode" alt="CROMARFx Logo">
      </router-link>

      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a class="navbar-toggle" id="isToggle" @click="toggleMenu">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>

      <ul class="buy-button list-inline mb-0">
        <li class="list-inline-item button mb-0 ps-1">
          <router-link to="/company/book-demo" class="btn btn-outline-primary btn-sm btn-pills nav-btn">
            Book a Demo
          </router-link>
        </li>
        <li class="list-inline-item button mb-0 ps-1">
          <router-link to="/company/contacts" class="btn btn-primary btn-sm btn-pills nav-btn">
            Contact Us
          </router-link>
        </li>
      </ul><!--end login button-->

      <div id="navigation">
        <!-- Navigation Menu-->
        <ul class="navigation-menu">
          <li><router-link to="/" class="sub-menu-item">Home</router-link></li>

          <li class="has-submenu parent-menu-item">
            <a href="javascript:void(0)">Company</a><span class="menu-arrow"></span>
            <ul class="submenu">
              <li><router-link to="/company/about" class="sub-menu-item">About Us</router-link></li>
              <li><router-link to="/company/history" class="sub-menu-item">History</router-link></li>
              <li><router-link to="/company/careers" class="sub-menu-item">Careers</router-link></li>
              <li><router-link to="/company/partnership" class="sub-menu-item">Partnership</router-link></li>
            </ul>
          </li>

          <li class="has-submenu parent-menu-item">
            <a href="javascript:void(0)">Product</a><span class="menu-arrow"></span>
            <ul class="submenu">
              <li>
                <router-link to="/product/feature-overview" class="sub-menu-item">
                  Feature Overview <br />
                  <em class="text-muted mt-2 fw-lighter">Discover smart e2e procurement</em>
                </router-link>
              </li>
              <li><router-link to="/product/process-workflow" class="sub-menu-item">
                  Process Workflow <br />
                  <em class="text-muted mt-2 fw-lighter">Integrate CromaRFx into your workflow</em>
                </router-link>
              </li>
              <li><router-link to="/product/pricing" class="sub-menu-item">Pricing</router-link></li>
            </ul>
          </li>

          <li class="has-submenu parent-menu-item">
            <a href="javascript:void(0)">Solution</a><span class="menu-arrow"></span>
            <ul class="submenu">
              <li><router-link to="/solution/srm" class="sub-menu-item">
                  Supplier Relationship Management {SRM} <br />
                  <em class="text-muted mt-2 fw-lighter">Access supplier information</em>
                </router-link>
              </li>
              <li><router-link to="/solution/procure-to-pay" class="sub-menu-item">
                  Procure-to-Pay <br />
                  <em class="text-muted mt-2 fw-lighter">Requisition to payment - in one place</em>
                </router-link>
              </li>
              <li><router-link to="/solution/integration" class="sub-menu-item">
                  Accounting & ERP Integration <br />
                  <em class="text-muted mt-2 fw-lighter">Seamles data transfer &amp; integration</em>
                </router-link>
              </li>
              <li><router-link to="/solution/bi" class="sub-menu-item">
                  Business Intelligence <br />
                  <em class="text-muted mt-2 fw-lighter">Informed decision-making based on <br />reports &amp;
                    analytics</em>
                </router-link>
              </li>
            </ul>
          </li>

          <li class="has-submenu parent-menu-item">
            <a href="javascript:void(0)">Resources</a><span class="menu-arrow"></span>
            <ul class="submenu active">
              <li><router-link to="/resources/blog" class="sub-menu-item">Blog</router-link></li>
              <li><router-link to="/resources/case-studies" class="sub-menu-item">Case Studies</router-link></li>
              <li><router-link to="/resources/e-books" class="sub-menu-item">E-books</router-link></li>
              <li><router-link to="/resources/guides" class="sub-menu-item">Guides</router-link></li>
              <li><router-link to="/resources/webinars" class="sub-menu-item">Webinars</router-link></li>
              <li><router-link to="/resources/white-papers" class="sub-menu-item">White Papers</router-link></li>
              <li><router-link to="/resources/press-releases" class="sub-menu-item">Press Releases</router-link></li>
            </ul>
          </li>

        </ul><!--end navigation menu-->
      </div><!--end navigation-->
    </div><!--end container-->
  </header>
</template>

<script setup>
window.addEventListener('load', function () {
  if (document.getElementById("navigation")) {
    var elements = document.getElementById("navigation").getElementsByTagName("a");
    for (var i = 0, len = elements.length; i < len; i++) {
      elements[i].onclick = function (elem) {
        if (elem.target.getAttribute("href") === "javascript:void(0)") {
          var submenu = elem.target.nextElementSibling.nextElementSibling;
          submenu.classList.toggle('open');
        }
      }
    }
  }
}, false)

const toggleMenu = () => {
  document.getElementById('isToggle').classList.toggle('open');
  var isOpen = document.getElementById('navigation')
  if (isOpen.style.display === "block") {
    isOpen.style.display = "none";
  } else {
    isOpen.style.display = "block";
  }
}

</script>

<style scoped>
.nav-btn {
  font-size: .8rem;
}
</style>
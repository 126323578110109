import { createApp, provide } from "vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createPinia } from "pinia";
import apolloClient from "@/plugins/apollo";
import App from "./App.vue";
import router from "./router";
import Vue3Progress from "vue3-progress";
import VueSocialSharing from "vue-social-sharing";

/* layouts */
import defaultLayout from "@/layouts/default";
import authLayout from "@/layouts/auth";

// ngprogress
const ngprogress_options = {
	position: "fixed",
	height: "5px",
	color: "#00b4ad",
};

const apiURL = process.env.VUE_APP_API_URL;

createApp(App)
	.provide(DefaultApolloClient, apolloClient)
	.provide("apiUrl", apiURL)
	.use(router)
	.use(createPinia())
	.use(Vue3Progress, ngprogress_options)
	.use(VueSocialSharing)
	.component("default", defaultLayout)
	.component("auth", authLayout)
	.mount("#app");

<template>
  <transition name="fade" mode="out-in">
    <component :is="$route.meta.layout ?? 'default'">
      <router-view v-slot="{ Component }" :key="$route.fullPath">
        <transition name="fade" mode="out-in">
          <KeepAlive>
            <component :is="Component"></component>
          </KeepAlive>
        </transition>
         <vue3-progress />
      </router-view>
    </component>
  </transition>
</template>

<script>
import { CardShimmer, ParagraphShimmer, FreeStyleShimmer } from 'vue3-shimmer'
export default {
  name: "App",
  components: [CardShimmer, ParagraphShimmer, FreeStyleShimmer],
  mounted() {
    this.$progress.finish();
  },
  created() {
    this.$progress.start();

    this.$router.beforeEach((to, from, next) => {
      this.$progress.start();
      next();
    });

    this.$router.afterEach((to, from) => {
      this.$progress.finish();
    });
  },
};
</script>

<style lang="css">
/* transition */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.09s ease;
}
</style>

<template>
  <section class="mt-4 py-4">
    <div class="container">
      <div class="row justify-content-center">
        <Carousel :autoplay=" 5000 " :itemsToShow=" 3.95 " :wrapAround=" true " :transition=" 500 ">
          <Slide v-for="     partner     in            partners           " :key=" `partner-${ partner.id }` ">
            <div class="col-lg-3 col-md-3 col-6 text-center py-4">
              <img :src=" `${ baseUrl }/media/partners/${ partner.logo }` " class="avatar avatar-custom"
                :alt=" partner.name " />
            </div>
          </Slide>
        </Carousel>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide } from 'vue3-carousel'
import apolloClient from '@/plugins/apollo'
import gql from 'graphql-tag'

const baseUrl = inject('apiUrl')
const partners = ref([])

onMounted(async () => {
  const { data: response } = await apolloClient.query({ query: gql`query { partners { id name email phone logo }}` })
  partners.value = response?.partners
})
</script>

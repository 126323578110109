<template>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="footer-py-60">
            <div class="row">
              <div class="col-lg-4 col-12 mb-0 mb-md-4 pb-0 pb-md-2">
                <router-link to="/" class="logo-footer">
                  <img src="/assets/images/favicon.png" height="48" alt="CROMARFx Logo">
                </router-link>
                <p class="mt-4">
                  CromaRFx is an easy-to-implement, cloud-hosted solution that automates your procurement process.<br />
                  Manage your procure-to-pay process using tight approval processes for an efficient, transparent
                  procurement process.
                </p>
                <ul class="list-unstyled social-icon foot-social-icon mb-0 mt-4">
                  <li class="list-inline-item mb-0"><a href="https://www.linkedin.com/company/croma-interactive"
                      target="_blank" class="rounded"><i class="uil uil-linkedin-alt align-middle"
                        title="LinkedIn"></i></a></li>
                  <li class="list-inline-item mb-0 mx-3"><a href="https://www.facebook.com/#" target="_blank"
                      class="rounded"><i class="uil uil-facebook-f align-middle" title="Facebook"></i></a></li>
                  <li class="list-inline-item mb-0 mx-3"><a href="https://twitter.com/#" target="_blank"
                      class="rounded"><i class="uil uil-twitter align-middle" title="Twitter"></i></a></li>
                  <li class="list-inline-item mb-0"><a href="mailto:info@cromarfx.com" class="rounded"><i
                        class="uil uil-envelope align-middle" title="email"></i></a></li>
                </ul><!--end icon-->
              </div><!--end col-->

              <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h5 class="footer-head">Product</h5>
                <ul class="list-unstyled footer-list mt-4">
                  <li><router-link to="/product/feature-overview" exact class="text-foot"><i
                        class="uil uil-angle-right-b me-1"></i> Feature Overview</router-link></li>
                  <li><router-link to="/product/process-workflow" exact class="text-foot"><i
                        class="uil uil-angle-right-b me-1"></i> Process Workflow</router-link></li>
                  <li><router-link to="/product/pricing" exact class="text-foot"><i
                        class="uil uil-angle-right-b me-1"></i> Pricing</router-link></li>
                </ul>
              </div><!--end col-->

              <div class="col-lg-3 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h5 class="footer-head">Solution</h5>
                <ul class="list-unstyled footer-list mt-4">
                  <li><router-link to="/solution/srm" exact class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                      Supplier Management</router-link></li>
                  <li><router-link to="/solution/procure-to-pay" exact class="text-foot"><i
                        class="uil uil-angle-right-b me-1"></i> Procure to Pay</router-link></li>
                  <li><router-link to="/solution/integration" exact class="text-foot"><i
                        class="uil uil-angle-right-b me-1"></i> ERP Integration</router-link></li>
                  <li><router-link to="/solution/bi" exact class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                      Business Intelligence</router-link></li>
                </ul>
              </div><!--end col-->

              <div class="col-lg-2 col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <h5 class="footer-head">Resources</h5>
                <ul class="list-unstyled footer-list mt-4">
                  <li><router-link to="/resources/blog" exact class="text-foot"><i class="uil uil-angle-right-b me-1"></i>
                      Blog</router-link></li>
                  <li><router-link to="/resources/guides" exact class="text-foot"><i
                        class="uil uil-angle-right-b me-1"></i> Guides</router-link></li>
                  <li><router-link to="/resources/case-studies" exact class="text-foot"><i
                        class="uil uil-angle-right-b me-1"></i> Case Studies</router-link></li>
                  <li><router-link to="/resources/e-books" exact class="text-foot"><i
                        class="uil uil-angle-right-b me-1"></i> E-books</router-link></li>
                </ul>
              </div><!--end col-->


            </div><!--end row-->
          </div>
        </div><!--end col-->
      </div><!--end row-->
    </div><!--end container-->

    <div class="footer-py-30 footer-bar">
      <div class="container text-center">
        <div class="row align-items-center">
          <div class="col-sm-6">
            <div class="text-sm-start">
              <p class="mb-0">&copy; {{ setup.year }} {{ setup.name }}. All rights reserved. By <a
                  href="https://canonicalapps.co.ke" target="_blank" class="text-reset">Canonical Apps</a>.
              </p>
            </div>
          </div><!--end col-->

          <div class="col-sm-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <ul class="list-unstyled text-sm-end mb-0">
              <li class="list-inline-item"><router-link to="/company/privacy" title="Privacy Policy"
                  exact>Privacy</router-link></li>
              <li class="list-inline-item text-center px-2">|</li>
              <li class="list-inline-item"><router-link to="/company/terms" title="Terms of Use" exact>Terms</router-link>
              </li>
            </ul>
          </div><!--end col-->
        </div><!--end row-->
      </div><!--end container-->
    </div>
  </footer>
</template>

<script setup>
const setup = {
  name: 'CROMARFx',
  year: (new Date()).getFullYear()
}

</script>
<template>
	<div>
		<!-- Navbar Start -->
			<Header />
		<!-- Navbar End -->

		<!-- Hero Start -->
		<section class="bg-profile d-table w-100 bg-primary"
			style="background: url('/assets/images/pages/auth/bg.png') center center;">
			<div class="container">
				<div class="row">
					<div class="col-lg-12">
						<div class="card public-profile border-0 rounded shadow" style="z-index: 1;">
							<div class="card-body">
								<div class="row align-items-center">
									<div class="col-lg-2 col-md-3 text-md-start text-center">
										<img :src="`${apiUrl}/media/avatars/${userInfo?.profile.avatar}`" loading="lazy"
											class="avatar avatar-large rounded-circle shadow d-block mx-auto" :alt="`${userInfo?.profile.firstName} ${userInfo?.profile.firstName}`" />
									</div><!--end col-->

									<div class="col-lg-10 col-md-9">
										<div class="row align-items-end">
											<div class="col-md-7 text-md-start text-center mt-4 mt-sm-0">
												<h3 class="title mb-0 text-capitalize"> {{`${userInfo?.profile.firstName} ${userInfo?.profile.lastName}`}}</h3>
												<small class="text-muted h6 me-2 fw-light text-capitalize">{{userInfo?.profile.nickname ?? 'Content Creator'}}</small>
											</div><!--end col-->
										</div><!--end row-->
									</div><!--end col-->
								</div><!--end row-->
							</div>
						</div>
					</div><!--end col-->
				</div><!--end row-->
			</div><!--ed container-->
		</section><!--end section-->
		<!-- Hero End -->

		<!-- Profile Start -->
		<section class="section mt-60">
			<div class="container mt-lg-3">
				<div class="row">
					
					<Sidebar />

					<slot />

				</div><!--end row-->
			</div><!--end container-->
		</section><!--end section-->
		<!-- Profile Setting End -->

		<!-- Footer Start -->
			<Footer />
		<!-- Footer End -->
	</div>
</template>

<script setup>
	import Header from './partials/_header'
	import Footer from './partials/_footer'
	import Sidebar from './partials/_sidebar'
	import { useAuthStore } from "@/store/useAuth"
	import { storeToRefs } from 'pinia'
	import { inject } from 'vue'

	const apiUrl = inject('apiUrl')

	const authStore = useAuthStore()
	const { userInfo } = storeToRefs(authStore)

</script>